#notification-service-now {
  width: 100%;

  .notification-config-row {
    display: flex;
    max-width: calc(660px + 80px);

    > :not(:first-child) {
      margin-left: 5px;
    }
  }

  .notification-config-sn-assignment-group,
  .notification-config-sn-category,
  .notification-config-sn-sub-category {
    display: flex;
    padding-bottom: 5px;
    flex: 1 1 auto;

    .alt-text-input > input {
      height: 32px;
    }
    .alt-field-error {
      bottom: -8px;
      white-space: nowrap;
    }
  }
}
